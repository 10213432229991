import React from "react";
import { Image, Box } from "@pancakeswap/uikit";
import { SpinnerProps } from "./types";

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  return (
    <Box width={80} height={80} position="relative">
      <Image
        width={80}
        height={80}
        src="/g0R5.gif"
      />
    </Box>
  );
};

export default Spinner;
